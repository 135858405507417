@tailwind components;
@tailwind utilities;

body{
    font-family: 'Source Sans Pro', sans-serif;
}

a{
    text-decoration: none!important;
}

.App{
    padding: 0 !important;
    min-height: 80vh;
}

.bigLine{
    height: 3px;
    margin-top: -3px;
    width: 50px;
    background: rgb(60,13,153);
}

.hovecp{
    font-size: 17px!important;
}

.loadingWrapper {
    position: absolute;
    display: none;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 1.2em;
    color: #FFF;
    background: rgba(0, 0, 0, 1);
    z-index: 800;
    transition: all 0.10s ease-in;
}

.contentWrapper{
    z-index: 1;
}

.loadingLogo {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
}

.loadingLogo img{
    width: 100px;
    animation: LogoZoomInZoomOut 1.2s infinite;
}

.navLogoSafepass:hover{
    animation: NavLogoZoomInZoomOut 1.5s infinite;
}

@keyframes LogoZoomInZoomOut {
    0%{
        transform: scale(1, 1);
    }
    50%{
        transform: scale(1.2, 1.2);
    }
    100%{
        transform: scale(1, 1);
    }
}

@keyframes NavLogoZoomInZoomOut {
    0%{
        transform: scale(1, 1);
    }
    50%{
        transform: scale(1.1, 1.1);
    }
    100%{
        transform: scale(1, 1);
    }
}
